<template>
<div class="contact">
    <div class="container">

        <h1>{{ $t('contact.title') }}</h1>

        <p>
            {{ $t('contact.text') }}
        </p>

        <p>
            <a :href="$t('contact.url')" target="_blank">
                <icon name="arrow-right"/>
                {{ $t('contact.link') }}
            </a>
        </p>

    </div>
    <div class="container">
        <b>Kontakt für all Ihre Fragen im Zusammenhang mit Ihrer Bestellung:</b>
        <h5>Bestellbüro L+S</h5>
        <p>
            <a href="mailto:orders.uz@luechinger-schmid.ch">
                <icon name="envelope"/>
                orders.uz@luechinger-schmid.ch
            </a> <br>
            <a href="tel:+41585716050">
                <icon name="phone"/>
                058 571 60 50
            </a>
        </p>

        <br>

        <b>Kontakt für Ihre weiteren Anliegen: / Kontakt für alle anderen Anliegen:</b>
        <h5>Verkauf Innendienst</h5>
        <p>
            <a href="mailto:vid-administration@luechinger-schmid.ch">
                <icon name="envelope"/>
                vid-administration@luechinger-schmid.ch
            </a> <br>
            <a href="tel:+41585716001">
                <icon name="phone"/>
                058 571 60 01
            </a>
        </p>

    </div>
</div>
</template>

<style scoped lang="scss">

    @import "~@astina/paloma-shop-portal-ui/src/css/theme";

    h1 {
        margin-bottom: 2rem;
    }

    .contact {
        margin-top: 2rem;
    }

    .contact p {
        font-size: 1.1rem;
    }

    .contact a {
        font-size: 1.1rem;
        font-weight: $weight-text-medium;
        color: $primary-9;
    }

</style>
<script setup lang="ts">
</script>